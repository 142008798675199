const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.7986 14.7844L12.7467 11.7324C13.9135 10.3054 14.4872 8.48446 14.349 6.64632C14.2109 4.80818 13.3716 3.09344 12.0047 1.85679C10.6377 0.620132 8.84778 -0.0438273 7.00504 0.00224705C5.16229 0.0483214 3.40775 0.800904 2.10433 2.10433C0.800904 3.40775 0.0483214 5.16229 0.00224705 7.00504C-0.0438273 8.84778 0.620132 10.6377 1.85679 12.0047C3.09344 13.3716 4.80818 14.2109 6.64632 14.349C8.48446 14.4872 10.3054 13.9135 11.7324 12.7467L14.7844 15.7986C14.9196 15.9293 15.1008 16.0016 15.2889 16C15.477 15.9983 15.6569 15.9229 15.7899 15.7899C15.9229 15.6569 15.9983 15.477 16 15.2889C16.0016 15.1008 15.9293 14.9196 15.7986 14.7844ZM7.1961 12.9346C6.06113 12.9346 4.95165 12.5981 4.00795 11.9675C3.06426 11.3369 2.32874 10.4407 1.89441 9.39213C1.46007 8.34356 1.34643 7.18973 1.56785 6.07657C1.78927 4.96341 2.33581 3.9409 3.13836 3.13836C3.9409 2.33581 4.96341 1.78927 6.07657 1.56785C7.18973 1.34643 8.34356 1.46007 9.39213 1.89441C10.4407 2.32874 11.3369 3.06426 11.9675 4.00795C12.5981 4.95165 12.9346 6.06113 12.9346 7.1961C12.9329 8.71752 12.3278 10.1761 11.252 11.252C10.1761 12.3278 8.71752 12.9329 7.1961 12.9346Z"
        fill="#6F767E"
      />
    </svg>
  );
};
export default SearchIcon;
