const CloseBtnIcom = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M14.0057 3.99427C13.8493 3.83786 13.6371 3.75 13.4159 3.75C13.1947 3.75 12.9825 3.83786 12.826 3.99427L9 7.82032L5.17395 3.99427C5.0175 3.83786 4.80533 3.75 4.58411 3.75C4.36289 3.75 4.15072 3.83786 3.99427 3.99427C3.83786 4.15072 3.75 4.36289 3.75 4.58411C3.75 4.80533 3.83786 5.0175 3.99427 5.17395L7.82032 9L3.99427 12.826C3.83786 12.9825 3.75 13.1947 3.75 13.4159C3.75 13.6371 3.83786 13.8493 3.99427 14.0057C4.15072 14.1621 4.36289 14.25 4.58411 14.25C4.80533 14.25 5.0175 14.1621 5.17395 14.0057L9 10.1797L12.826 14.0057C12.9825 14.1621 13.1947 14.25 13.4159 14.25C13.6371 14.25 13.8493 14.1621 14.0057 14.0057C14.1621 13.8493 14.25 13.6371 14.25 13.4159C14.25 13.1947 14.1621 12.9825 14.0057 12.826L10.1797 9L14.0057 5.17395C14.1621 5.0175 14.25 4.80533 14.25 4.58411C14.25 4.36289 14.1621 4.15072 14.0057 3.99427Z"
        fill="#1A1D1F"
      />
    </svg>
  );
};
export default CloseBtnIcom;
